<template>
  <div class="ibox companies">
    <div class="ibox-title">
      <h2>{{ $t('admin_companies.title') }}</h2>
    </div>
    <div :class="'ibox-content p-md' + (listCompaniesLoading ? ' sk-loading' : '')">

      <div v-if="listCompaniesLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      <p v-if="showNumberOfRows">Number of rows: {{numberOfRows}}</p>
    
      <b-table 
          outlined striped
          :items="listCompanies"
          :fields="listCompaniesFields"
          ref="listCompaniesElement">
        <template v-slot:cell(select)="row">
          <p-check class="p-default p-curve p-primary" color="success" :value="row.item._id" v-model="listCompaniesSelected"></p-check>
        </template>
        <template v-slot:cell(web)="row">
          <a v-if="row.item.web" :href="row.item.web" target="_blank">{{row.item.web}}</a>
        </template>
        <template v-slot:cell(options)="row">
          <span v-if="isUpdateAllowed">
            <a href="javascript:void(0)" @click="showUpdateCompanyModal(row.item)">{{$t('company.info.update')}}</a>
            /
          </span>
          <span v-if="isRemoveAllowed">
            <a href="javascript:void(0)" @click="confirmRemoveCompany(row.item)">{{$t('company.info.delete')}}</a>
            /
          </span>
          <a href="javascript:void(0)" @click="showCompanyPage(row.item)">{{$t('company.info.details')}}</a>
        </template>
        <template v-slot:cell(published)="row">
          <span v-if="getPublishedCompany(row.item._id, row.item.name) != undefined">Published - </span>
          <a href="javascript:void(0)" @click="showPublishCompanyModal(row.item)">{{$t('company.info.publish')}}</a> / 
          <a href="javascript:void(0)" @click="confirmRemovePublishedCompany(row.item)">{{$t('company.info.delete')}}</a>
        </template>
      </b-table>

      <button v-if="isCreateAllowed" class="btn btn-primary" @click="showCreateCompanyModal()">{{$t('company.info.create-button')}}</button>

      <b-modal size="lg" ref="updateCompanyModal" :title="'Update company ('+ companyToUpdate.name +')'" hide-footer lazy>
        <UpdateCompany :company="companyToUpdate" v-on:company-updated="onCompanyUpdated" />
      </b-modal>

      <b-modal size="lg" ref="createCompanyModal" title="Create new company" hide-footer lazy>
        <CreateCompany v-on:company-created="onCompanyCreated" />
      </b-modal>

      <b-modal size="lg" ref="publishCompanyModal" :title="'Publish company ('+ companyToUpdate.name +')'" hide-footer lazy>
        <PublishCompany :company="companyToUpdate" v-on:company-published="onCompanyPublished" />
      </b-modal>

      <b-modal ref="removeCompanyModal" 
          hide-header
          @ok="removeCompany">
        {{$t('company.info.delete-confirmation', {companyName: companyToRemove.name})}}
      </b-modal>

      <b-modal ref="removePublishedCompanyModal" 
          hide-header
          @ok="removePublishedCompany">
        {{$t('company.info.delete-confirmation', {companyName: companyToRemove.name})}}
      </b-modal>
    </div>
  </div>
</template>

<style scoped>
  
</style>



<script lang="ts">
import { Ref, ComputedRef, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive, toRefs } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import UpdateCompany from './Update.vue';
import CreateCompany from './Create.vue';
import PublishCompany from './Publish.vue';
import { languagesTypes } from '@fwk-client/store/types';

import * as api from '@fwk-client/utils/api';

import { useCompanyAdmin } from '../../../composables/useCompanyAdmin';
import { usePublishAdmin } from '../../../composables/usePublishAdmin';
import { useModuleAdmin } from '../../../composables/useModuleAdmin';

export default defineComponent({
  props: {
  },
  components: {
    UpdateCompany,
    CreateCompany,
    PublishCompany
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();
    const { modules, selectedModule, callModuleAdmin } = useModuleAdmin(props, context);
    const { selectedCompany, removeCompany:removeCompanyHelper } = useCompanyAdmin(props, context);
    const { getPublishedCompany, removePublishedCompany:removePublishedCompanyHelper, updateListPublishedCompanies } = usePublishAdmin(props, context);
    updateListPublishedCompanies();

    const isCreateAllowed:Ref<boolean> = ref(false);
    const isUpdateAllowed:Ref<boolean> = ref(false);
    const isRemoveAllowed:Ref<boolean> = ref(false);

    const listCompaniesElement:Ref<HTMLElement|null> = ref(null);
    const createCompanyModal:Ref<HTMLElement|null> = ref(null);
    const updateCompanyModal:Ref<HTMLElement|null> = ref(null);
    const removeCompanyModal:Ref<HTMLElement|null> = ref(null);
    const publishCompanyModal:Ref<HTMLElement|null> = ref(null);
    const removePublishedCompanyModal:Ref<HTMLElement|null> = ref(null);

    const isMultiSelectAllowed:Ref<boolean> = ref(false);
    const showNumberOfRows:Ref<boolean> = ref(false);

    const numberOfRows:Ref<number> = computed(() => {
        return listCompanies.value.length
    })

    const companyToRemove:Ref<any> = ref({});
    const companyToUpdate:Ref<any> = ref({});

    const listCompaniesLoading:Ref<boolean> = ref(false);
    const listCompaniesSelected:Ref<any[]> = ref([]);

    const listCompaniesFields:Ref<any[]> = ref([
      {
        key: "select",
        label: "",
        class: (isMultiSelectAllowed.value ? "" : "hidden"),
      },
      {
        key: "name",
        label: ""
      },
      {
        key: "web",
        label: ""
      },
      {
        key: "options"
      },
      {
        key: "published"
      }
    ]);

    onMounted(() => {
      listCompaniesFields.value[1].label = app.$t('company.info.name') as string;
      listCompaniesFields.value[2].label = app.$t('company.info.web') as string;
      listCompaniesFields.value[3].label = app.$t('company.headers.options') as string;
      listCompaniesFields.value[4].label = app.$t('company.headers.published') as string;
    })

    const currentLanguageCode:ComputedRef<string> = computed(() => {
      return $store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE]
    })

    const onCompanyCreated = () => {
      // @ts-ignore
      createCompanyModal.value.hide()
      updateListCompanies();
    }

    const onCompanyUpdated = (company:any) => {
      // We update the company
      companyToUpdate.value = company;
      updateListCompanies();
    }

    const onCompanyPublished = (company:any) => {
      // We update the company
      companyToUpdate.value = company;
      updateListPublishedCompanies();
    }
    
    const confirmRemoveCompany = (company:any) => {
      companyToRemove.value = company;
      // @ts-ignore
      removeCompanyModal.value.show()
    }

    const confirmRemovePublishedCompany = (company:any) => {
      companyToRemove.value = company;
      // @ts-ignore
      removePublishedCompanyModal.value.show()
    }

    const showUpdateCompanyModal = (company:any) => {
      companyToUpdate.value = company;
      // @ts-ignore
      updateCompanyModal.value.show()
    }

    const showCreateCompanyModal = () => {
      // @ts-ignore
      createCompanyModal.value.show()
    }

    const showPublishCompanyModal = (company:any) => {
      companyToUpdate.value = company;
      // @ts-ignore
      publishCompanyModal.value.show();
    }

    const showCompanyPage = (company:any) => {
      $router.push("/"+currentLanguageCode+"/company/"+company._id).catch((err:any) => {});
    }

    const listCompanies:Ref<any[]> = ref([]);

    const updateListCompanies = () => {
      var options:api.ApiVueOptions =  {
        app: app
      }

      var input = {}

      listCompaniesLoading.value = true;

      return api.postAPI('/api/admin/company/companies/list', input, options).then((response:any) => {
        // We check the additional fields
        if(response.fields) {
          listCompaniesFields.value.splice(2, 0, ...(response.fields.map((field:string) => {
            return {
              key: field,
              label: app.$t('company.headers.' + field) as string
            }
          })));
        }
        if(response.options) {
          isCreateAllowed.value = response.options.isCreateAllowed;
          isUpdateAllowed.value = response.options.isUpdateAllowed;
          isRemoveAllowed.value = response.options.isRemoveAllowed;
          isMultiSelectAllowed.value = response.options.isMultiSelectAllowed;
          showNumberOfRows.value = response.options.showNumberOfRows;
        }

        listCompaniesLoading.value = false;

        if(response.companies) {  
          listCompanies.value = response.companies
        }
        else {
          listCompanies.value = [];
        }
      })
    }
    updateListCompanies();

    const removeCompany = () => {

      removeCompanyHelper(companyToRemove.value._id).then((result) => {
        if(result.removed) {
          // We update the list of companies
          updateListCompanies();
        }
        // We reset the user to remove
        companyToRemove.value = {};
      });

    }

    const removePublishedCompany = () => {

      removePublishedCompanyHelper(companyToRemove.value._id).then((result) => {
        if(result.removed) {
          // We update the list of companies
          updateListPublishedCompanies();
        }
        // We reset the user to remove
        companyToRemove.value = {};
      });

    }

    return {
      listCompaniesSelected,
      listCompaniesLoading,
      showNumberOfRows,
      numberOfRows,
      listCompanies,
      listCompaniesFields,
      isCreateAllowed,
      isUpdateAllowed,
      isRemoveAllowed,
      isMultiSelectAllowed,
      showUpdateCompanyModal,
      showCreateCompanyModal,
      showCompanyPage,
      confirmRemoveCompany,
      companyToUpdate,
      companyToRemove,
      onCompanyUpdated,
      onCompanyCreated,
      listCompaniesElement,
      createCompanyModal,
      updateCompanyModal,
      removeCompanyModal,
      removeCompany,
      getPublishedCompany,
      removePublishedCompanyModal,
      confirmRemovePublishedCompany,
      removePublishedCompany,
      publishCompanyModal,
      showPublishCompanyModal,
      onCompanyPublished
    }
  }
});
</script>