<template>
  
    <form role="form" @submit="onFormSubmit" name="publish-company">

      <h4>{{$t('admin_companies.publish.company')}}</h4>
      <ul>
        <li>{{$t('company.info.name')}}: {{company.name}}
        <li>{{$t('company.info.lastUpdateDateTime')}}: {{ formatDateTime(company.lastUpdateDateTime) }}</li>
      </ul>


      <div class="production-company">
        <h4>{{$t('admin_companies.publish.production-company')}}</h4>
        <ul v-if="productionCompany">
          <li>{{$t('company.info.name')}}: {{productionCompany.name}}
          <li>{{$t('company.info.lastUpdateDateTime')}}: {{ formatDateTime(productionCompany.lastUpdateDateTime) }}</li>
        </ul>
        <span v-if="productionCompany == null">{{$t('admin_companies.publish.no-production-company')}}</span>
      </div>
      
      <button class="btn btn-primary ladda-button publish-company" data-style="zoom-in" type="submit">{{$t('admin_companies.publish.button')}}</button>
    </form>

</template>

<style scoped>
  .fa-check.active, .fa-user-circle-o.active {
    color:green;
  }
  .fa-check.disabled, .fa-user-circle-o.disabled {
    color:red;
  }

  
</style>

<script lang="ts">
import { toRefs, Ref, ref, defineComponent, PropType, computed, onMounted } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import * as Ladda from 'ladda';
import { languagesTypes } from '@fwk-client/store/types';
import { formatDate } from '@igotweb-node-api-utils/formatter';

import { useCompanyAdmin } from '../../../composables/useCompanyAdmin';
import { usePublishAdmin } from '../../../composables/usePublishAdmin';
import { useModuleAdmin } from '../../../composables/useModuleAdmin';

export default defineComponent({
  props: {
    company: Object as PropType<any>,
    productionCompany : Object as PropType<any>
  },
  components: {
      
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { modules, selectedModule, callModuleAdmin } = useModuleAdmin(props, context);
    const { selectedCompany, removeCompany:removeCompanyHelper } = useCompanyAdmin(props, context);
    const { publishCompany } = usePublishAdmin(props, context);

    const { company, productionCompany } = toRefs(props);

    var laddaSubmit:Ladda.LaddaButton|null = null;

    onMounted(() => {
      var publishButton:HTMLButtonElement|null = document.querySelector( 'form[name=publish-company] button.ladda-button.publish-company' );
      laddaSubmit = Ladda.create(publishButton!);
    })

    const onFormSubmit = (evt:Event) => {
      evt.preventDefault();

      laddaSubmit!.start();

      return publishCompany(company.value._id).then((result:any) => {
        laddaSubmit!.stop();
        if(result.published) {  
          context.emit('company-published', result.company);
        }
      })
    }

    const currentLanguageCode = $store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE];
    
    const formatDateTime = (date:Date) => {
      return formatDate(date, currentLanguageCode);
    }

    return {
      formatDateTime,
      company,
      productionCompany,
      onFormSubmit
    }
  }
})
</script>