import { Ref, watch, computed, ref, reactive, defineProps, PropType } from "@fwk-node-modules/vue";
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { authenticationTypes } from '@fwk-client/store/types';

import { useModuleAdmin } from "./useModuleAdmin";
import { escapeRegExp } from "@igotweb-node-api-utils/misc";

interface PublishAdminInput {
}

export function usePublishAdmin(props:PublishAdminInput, {emit}:any) { 
  const app = getApp();
  const store = useStore();

  const { selectModule, selectedModule, callModuleAdmin, updateListModules } = useModuleAdmin(props, {emit});
  const hasUserRole = store.getters['authentication/' + authenticationTypes.getters.HAS_USER_ROLE];

  var isListLoading:Ref<boolean> = ref(false);

  const listPublishedCompanies:Ref<any[]> = ref([]);

  const updateListPublishedCompanies = async () => {

    isListLoading.value = true;
    try {
      var response = await callModuleAdmin('/companies/published');
      listPublishedCompanies.value = response.companies ? response.companies : [];
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
  }

  /**
   * This method publish company from staging to production
   * @param route - the route to publish
   * @returns 
   */
  const publishCompany = async (companyID:string) => {

    var results:{published:boolean, company:any} = {
      published: false,
      company: {}
    }

    try {
      var response = await callModuleAdmin('/'+companyID+'/pusblished/publish');
      
      if(response.published) {
        results.published = true;
        results.company = response.company
      }
    }
    catch(error) {
      console.log(error);
    }

    return results;
  }

  const getPublishedCompany = (companyID:string, companyName?:string) => {
    let searchRegExp = companyName ? new RegExp('^' + escapeRegExp(companyName) + '$',"i") : undefined;
    var indexCompany = listPublishedCompanies.value.findIndex((company:any) => {
      if(company._id == companyID) { return true }
      if(searchRegExp && company.name.match(searchRegExp) != null) {
        return true;
      }
      return false;
    });
     if(indexCompany > -1) {
      return listPublishedCompanies.value[indexCompany];
     }
     return undefined;
   }

  // We check when there is a new agency selected
  watch(
    selectedModule,
    (val:any, oldVal:any) => {
      // We update the categories when the selected agency is updated
      updateListPublishedCompanies();
    },
    { deep: false }
  )

  const removePublishedCompany = async (companyID:string) => {

    var result:any = {
      removed:false
    }

    try {
      var response = await callModuleAdmin('/'+companyID+'/pusblished/publish');
      if(response.removed) {
        result.removed = true;
        updateListModules();
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return result;
  }

  return {
    getPublishedCompany,
    isListLoading,
    listPublishedCompanies,
    updateListPublishedCompanies,
    publishCompany,
    removePublishedCompany
    
  }
  
}