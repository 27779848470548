<template>
  
    <form role="form" @submit="onFormSubmit" name="create-company">
        <div class="form-group">
            <label for="createName" class="sr-only">Name</label>
            <input id="createName" type="text" placeholder="Name" class="form-control" v-model="companyForm.name" required />
        </div>
        <button class="btn btn-primary ladda-button" data-style="zoom-in" type="submit">Create</button>
    </form>

</template>


<script lang="ts">
import { messagesTypes, MessageTypes } from '@fwk-client/store/types';


import { Ref, ComputedRef, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive, toRefs } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import * as Ladda from 'ladda';

import { useCompanyAdmin } from '../../../composables/useCompanyAdmin';
import { useModuleAdmin } from '../../../composables/useModuleAdmin';

export default defineComponent({
  props: {},
  components: {},
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();
    const { modules, selectedModule, callModuleAdmin } = useModuleAdmin(props, context);
    const { selectedCompany, isCompanyLoading, createCompany, companyForm } = useCompanyAdmin(props, context);

    var laddaSubmit:Ladda.LaddaButton|null = null;

    onMounted(() => {
      var button:HTMLButtonElement|null = document.querySelector( 'form[name=create-company] button' );
      laddaSubmit = Ladda.create(button!);
    })

    const onFormSubmit = (evt:Event) => {
      // We prevent submit of the page
      evt.preventDefault();

      laddaSubmit!.start();
      
      createCompany().then((result) => {
        if(result.created) {  
          context.emit('company-created', result.company);
        }
        laddaSubmit!.stop();
      });
    }

    return {
      onFormSubmit,
      companyForm
    }
  }
});
</script>