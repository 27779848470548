<template>
  <section id="content">
    <PageTitle title="Eurofiscalis"></PageTitle>
    <div class="wrapper wrapper-content animated fadeInRight">

      <div class="row">
        <div class="col-lg-12">
          <ListCompanies></ListCompanies>
          <ListRequests></ListRequests>
        </div>
      </div>

    </div>

  </section>
</template>

<style scoped>
  
</style>



<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';
import * as api from '@fwk-client/utils/api';
import { messagesTypes, MessageTypes } from '@fwk-client/store/types';
import PageTitle from '../../panels/PageTitle.vue';
import ListCompanies from '../../../modules/company/components/panels/admin/ListCompanies.vue';
import ListRequests from '../../panels/admin/eurofiscalis/workers/ListRequests.vue';

@Component({
  components: { 
    PageTitle,
    ListCompanies,
    ListRequests
  }
})
export default class Eurofiscalis extends mixins(GenericPage) {
  
}
</script>