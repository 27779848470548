<template>
  
    <form role="form" @submit="onFormSubmit" name="update-company">
      <div class="row">
        <div class="col-lg-12">
          <h3>Logo</h3>
        </div>
      </div>

      <div class="row" v-if="companyLogo">
        <div class="col-lg-12 text-center m-xs">
          <img :src="getStaticURLFromPath(companyLogo.path)" height="50px" />
        </div>
      </div>

      <div class="form-group row">
        <label for="updateLogo" class="col-lg-3 col-form-label">{{ $t('company.info.logo') }}</label>
        <div class="col-lg-9">
          <b-form-file
            id="logo"
            v-model="companyForm.logo"
            :placeholder="$t('company.info.logo_placeholder')"
            :drop-placeholder="$t('company.info.logo_drop-placeholder')"
          />
        </div>
      </div>

      <div class="hr-line-dashed"></div>
      <div class="row">
        <div class="col-lg-12">
          <h3>Information</h3>
        </div>
      </div>
      <div class="form-group row">
          <label for="updateName" class="col-lg-3 col-form-label">Name</label>
          <div class="col-lg-9">
            <input id="updateName" type="text" placeholder="Name" class="form-control" v-model="companyForm.name">
          </div>
      </div>
      <div class="form-group row">
          <label for="updateSource" class="col-lg-3 col-form-label">{{$t('company.info.source')}}</label>
          <div class="col-lg-9">
            <select id="updateSource" class="form-control" v-model="companyForm.source">
              <option value="">{{$t('company.info.source_select')}}</option>
              <option v-for="(source, index) in companySources" :key="index" :value="source">{{source}}</option>
            </select>
          </div>
      </div>
      <div class="form-group row">
          <label for="updateSize" class="col-lg-3 col-form-label">Size</label>
          <div class="col-lg-9">
            <select id="updateSize" class="form-control" v-model="companyForm.size">
              <option value="">{{$t('company.info.nbOfEmployees_select')}}</option>
              <option v-for="(nb, index) in nbOfEmployees" :key="index" :value="nb">{{$t('nbOfEmployees.'+nb)}}</option>
            </select>
          </div>
      </div>
      <div class="form-group row">
          <label for="updateRevenue" class="col-lg-3 col-form-label">Revenue</label>
          <div class="col-lg-9">
            <input id="updateRevenue" type="text" placeholder="Revenue" class="form-control" v-model="companyForm.revenue">
          </div>
      </div>
      <div class="form-group row">
          <label for="updateWeb" class="col-lg-3 col-form-label">Web</label>
          <div class="col-lg-9">
            <input id="updateWeb" type="text" placeholder="Web" class="form-control" v-model="companyForm.web">
          </div>
      </div>
      <div class="form-group row">
          <label for="updateVatNumber" class="col-lg-3 col-form-label">VAT Number</label>
          <div class="col-lg-9">
            <div class="input-group">
              <input id="updateVatNumber" type="text" placeholder="VAT Number" class="form-control" v-model="companyForm.vatNumber">
              <span class="input-group-append">
                <button type="button" class="btn btn-primary validateVAT" @click="validateVAT">Validate</button>
              </span>
            </div>
          </div>
      </div>
      <div class="hr-line-dashed"></div>
      <div class="row">
        <div class="col-lg-12">
          <h3>Identifiers</h3>
        </div>
      </div>
      <UpdateIdentifiers v-bind:identifiers.sync="companyForm.identifiers" :companyID="company._id" />
      <div class="hr-line-dashed"></div>
      <div class="row">
        <div class="col-lg-12">
          <h3>Social Medias</h3>
        </div>
      </div>
      <SocialMedias v-bind:socialMedias.sync="companyForm.socialMedias" :companyID="company._id" />
      <div class="hr-line-dashed"></div>
      <div class="row">
        <div class="col-lg-12">
          <h3>Phones</h3>
        </div>
      </div>
      <UpdatePhones v-bind:phones.sync="companyForm.phones" />
      <div class="hr-line-dashed"></div>
      <div class="row">
        <div class="col-lg-12">
          <h3>EMails</h3>
        </div>
      </div>
      <UpdateEMails v-bind:emails.sync="companyForm.emails" />
      <div class="hr-line-dashed"></div>
      <div class="row">
        <div class="col-lg-12">
          <h3>Address</h3>
        </div>
      </div>
      <Address 
        v-bind:value.sync="companyForm.address"
        :options="addressOptions"
        :labelAsColumn="true"
      />
      <div class="hr-line-dashed"></div>
      <button class="btn btn-primary ladda-button update" data-style="zoom-in" type="submit">Update</button>
    </form>

</template>

<script lang="ts">
import { Ref, ComputedRef, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive, toRefs } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import * as api from '@fwk-client/utils/api';
import * as Ladda from 'ladda';
import Address from '@fwk-client/components/panels/input/Address.vue';
import UpdatePhones from '@root/src/client/components/panels/contact/phones/UpdatePhones.vue';
import UpdateEMails from '@root/src/client/components/panels/UpdateEMails.vue';
import UpdateIdentifiers from './UpdateIdentifiers.vue'
import SocialMedias from './SocialMedias.vue'
import { positions, nbOfEmployees } from '../../../../../types/common';
import { companySources } from '../../../types';


import { useCompanyAdmin } from '../../../composables/useCompanyAdmin';
import { useModuleAdmin } from '../../../composables/useModuleAdmin';

export default defineComponent({
  props: {
    company: {
      type: Object as PropType<any>,
      required: false
    }
  },
  components: {
    Address,
    UpdateIdentifiers,
    SocialMedias,
    UpdatePhones,
    UpdateEMails
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();
    const { modules, selectedModule, callModuleAdmin } = useModuleAdmin(props, context);
    const { selectedCompany, isCompanyLoading, companyForm, updateCompanyFormForUpdate, updateCompany } = useCompanyAdmin(props, context);

    const { company } = toRefs(props);

    const companyLogo = computed(() => {
      company.value.logo;
    })

    if(company.value) {
      updateCompanyFormForUpdate(company.value);
    }

    const addressOptions = {
      showName: true,
      labelAsColumn: true,
      nbAddressLines: 2,
      showGeolocation: true
    }

    var laddaSubmit:Ladda.LaddaButton|null = null;
    var laddaValidateVAT:Ladda.LaddaButton|null = null;

    onMounted(() => {
      var submitButton:HTMLButtonElement|null = document.querySelector( 'form[name=update-company] button.update' );
      laddaSubmit = Ladda.create(submitButton!);

      var validateVATButton:HTMLButtonElement|null = document.querySelector( 'form[name=update-company] button.validateVAT' );
      laddaValidateVAT = Ladda.create(validateVATButton!);
    })

    const validateVAT = (evt:Event) => {
      // We prevent submit of the page
      evt.preventDefault();

      var input = {
        "vatNumber" : companyForm.vatNumber,
        "countryCode" : (companyForm.address && companyForm.address.countryCode) ? companyForm.address.countryCode : undefined
      }

      var options:api.ApiVueOptions =  {
        app: app
      }

      laddaValidateVAT!.start();
      
      api.postAPI('/api/utils/companies/validateVAT', input, options).then((response:any) => {
        if(response.validated) {  
          
        }
        else {

        }

        laddaValidateVAT!.stop();
      });
    }

    const onFormSubmit = (evt:Event) => {
      // We prevent submit of the page
      evt.preventDefault();

      laddaSubmit!.start();
      
      updateCompany().then((result:any) => {
        if(result.updated) {  
          var company = result.company;
          // We emit event as user is updated
          context.emit('company-updated', company);
        }

        laddaSubmit!.stop();
      });
      
    }

    return {
      nbOfEmployees,
      companySources,
      onFormSubmit,
      companyLogo,
      companyForm,
      company,
      validateVAT,
      addressOptions
    }
  }
})
</script>